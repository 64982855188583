import React, { useState } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import DirectorCard from '@component/DirectorCard';
import DirectorDrawerForm from '@component/DirectorDrawerForm';
import { useAppSelector } from '@hook/hooks.hook';
import {
  selectIsLoadingCompany,
  selectPendingVerificationCompanyDirectors,
  selectUnverifiedCompanyDirectors,
  selectVerifiedCompanyDirectors,
} from '@selector/company.selector';
import { CompanyDirector } from '@interface/company.interface';
import AppLoader from '@component/AppLoader';
import TutorialLink from '@component/TutorialLink';

const DirectorSection = ({
  title,
  alertColor,
  subtitle,
  directors,
  handleEditDirector,
}: {
  title: string;
  subtitle: string;
  alertColor?: any;
  directors?: CompanyDirector[];
  handleEditDirector: (values: CompanyDirector) => void;
}) => {
  return (
    <Box>
      {Boolean(directors?.length) && (
        <Box my={2}>
          <Alert severity={alertColor || 'info'}>
            <AlertTitle sx={{ fontWeight: 'bold' }}>{title}</AlertTitle>
            {subtitle}
          </Alert>
        </Box>
      )}

      <Grid container spacing={2}>
        {directors?.map((director, index) => (
          <Grid item xs={12} sm={6} md={4} key={director.fullName + index}>
            <DirectorCard director={director} onEdit={handleEditDirector} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const CompanyDirectorsSection: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentDirector, setCurrentDirector] =
    useState<CompanyDirector | null>(null);

  const verifiedCompanyDirectors = useAppSelector(
    selectVerifiedCompanyDirectors,
  );
  const unverifiedCompanyDirectors = useAppSelector(
    selectUnverifiedCompanyDirectors,
  );
  const pendingVerificationCompanyDirectors = useAppSelector(
    selectPendingVerificationCompanyDirectors,
  );

  const isLoadingCompany = useAppSelector(selectIsLoadingCompany);

  const handleAddDirector = () => {
    setDrawerOpen(true);
  };

  const handleEditDirector = (director: CompanyDirector) => {
    setCurrentDirector(director);
    setDrawerOpen(true);
  };

  const onCloseDrawer = () => {
    setCurrentDirector(null);
    setDrawerOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Stack>
          <Typography variant="body1" fontSize="20px" fontWeight="bold">
            Company Directors
          </Typography>

          {!isLoadingCompany &&
            !unverifiedCompanyDirectors?.length &&
            !verifiedCompanyDirectors?.length &&
            !pendingVerificationCompanyDirectors?.length && (
              <Typography variant="subtitle1" color="grey.700">
                No company directors
              </Typography>
            )}
        </Stack>
        <Stack direction="row" gap={2}>
          <TutorialLink
            title={`How to complete "Company Director KYC"`}
            link="https://www.youtube.com/watch?v=wpQNFKluqb4"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddDirector}
          >
            Add Director
          </Button>
        </Stack>
      </Box>

      <Stack gap={3}>
        {Boolean(verifiedCompanyDirectors?.length) && (
          <DirectorSection
            title="Verified Directors"
            subtitle="This directors have been verified and can not be edited."
            directors={verifiedCompanyDirectors}
            handleEditDirector={handleEditDirector}
            alertColor="success"
          />
        )}

        {Boolean(unverifiedCompanyDirectors?.length) && (
          <DirectorSection
            title="Unverified Directors"
            subtitle="This directors have incomplete details or have not been submitted for verification"
            directors={unverifiedCompanyDirectors}
            handleEditDirector={handleEditDirector}
            alertColor="warning"
          />
        )}

        {Boolean(pendingVerificationCompanyDirectors?.length) && (
          <DirectorSection
            title="Under Review"
            subtitle="This directors have been submitted for verification and can not be edited."
            directors={pendingVerificationCompanyDirectors}
            handleEditDirector={handleEditDirector}
            alertColor="info"
          />
        )}
      </Stack>

      {isLoadingCompany && <AppLoader size={8} />}

      {drawerOpen && (
        <DirectorDrawerForm
          open={drawerOpen}
          director={currentDirector}
          onClose={onCloseDrawer}
        />
      )}
    </>
  );
};

export default CompanyDirectorsSection;
