import React, { useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { MIN_WITHDRAWAL_AMOUNT, WITHDRAWAL_FEES } from '@variable';
import warningGif from '@asset/gifs/warning.gif';

import PreviouslyUsedAddresses from './PreviouslyUsedAddresses';

export const validateAddress = (
  address: string,
  cryptocurrency: string,
): boolean => {
  switch (cryptocurrency) {
    case 'BTC':
      return (
        /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(address) ||
        /^bc1[ac-hj-np-z02-9]{39,59}$/.test(address)
      );
    case 'ETH':
      return /^0x[a-fA-F0-9]{40}$/.test(address);
    case 'USDT':
    case 'USDC':
      return /^T[a-zA-Z0-9]{33}$/.test(address); // TRC20 address format
    default:
      return false;
  }
};

interface WithdrawalDialogProps {
  open: boolean;
  balance: number;
  onClose: () => void;
  cryptocurrency: string;
  onConfirm: (amount: number, address: string) => void;
  previousAddresses: string[];
}

const WithdrawalDialog: React.FC<WithdrawalDialogProps> = ({
  open,
  onClose,
  balance,
  onConfirm,
  cryptocurrency,
  previousAddresses,
}) => {
  const [amount, setAmount] = useState<number | string>('');
  const [address, setAddress] = useState<string>('');
  const [amountError, setAmountError] = useState<string | null>(null);
  const [addressError, setAddressError] = useState<string | null>(null);

  const handleConfirm = () => {
    if (validate()) onConfirm(Number(amount), address);
  };

  const validate = () => {
    let valid = true;

    if (
      !amount ||
      Number(amount) > balance ||
      Number(amount) < MIN_WITHDRAWAL_AMOUNT[cryptocurrency]
    ) {
      setAmountError('Please enter a valid amount.');
      valid = false;
    } else {
      setAmountError(null);
    }

    if (!validateAddress(address, cryptocurrency)) {
      setAddressError('Please enter a valid address.');
      valid = false;
    } else {
      setAddressError(null);
    }

    return valid;
  };

  const handleAddressSelect = (selectedAddress: string) => {
    setAddress(selectedAddress);
    setAddressError(null);
  };

  const onSetMax = () => {
    setAmount(balance);
  };

  const disableSubmitButton =
    !amount ||
    Number(amount || 0) > Number(balance) ||
    Number(amount || 0) < MIN_WITHDRAWAL_AMOUNT[cryptocurrency] ||
    balance === 0 ||
    !address ||
    !validateAddress(address, cryptocurrency);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle fontSize="20px" fontWeight={600} fontFamily="Lato" mt={2}>
        Withdraw
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={onClose}
        size="small"
        sx={{
          position: 'absolute',
          right: 24,
          top: 32,
          color: 'grey.600',
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider variant="fullWidth" />
      <DialogContent>
        <Stack textAlign="center" gap={1} mb={3}>
          <Typography variant="body2">Available Balance</Typography>
          <Typography variant="h4" fontSize="30px" fontWeight={600}>
            {Number(balance).toLocaleString('en-US', {
              maximumFractionDigits: 2,
            })}{' '}
            {cryptocurrency}
          </Typography>
        </Stack>

        <Stack direction="column" sx={{ width: '320px' }}>
          <TextField
            label="Amount to Withdraw"
            variant="outlined"
            margin="normal"
            value={amount}
            placeholder={`${balance} ${cryptocurrency} available`}
            onChange={(e) => setAmount(e.target.value)}
            error={Boolean(amountError)}
            helperText={
              amountError ||
              `Withdrawal fee: ${WITHDRAWAL_FEES[cryptocurrency]} ${cryptocurrency}`
            }
            fullWidth
            InputProps={{
              endAdornment: (
                <Button variant="text" size="small" onClick={onSetMax}>
                  max
                </Button>
              ),
            }}
          />

          <TextField
            label={`${cryptocurrency} Address`}
            variant="outlined"
            margin="normal"
            value={address}
            fullWidth
            onChange={(e) => setAddress(e.target.value)}
            error={Boolean(addressError)}
            helperText={addressError}
          />

          <Alert
            sx={{ mt: 2 }}
            severity="warning"
            icon={<img src={warningGif} height={32} width={32} alt="sd" />}
          >
            Please ensure that your network is TRC20 only. Using any other
            network may result in the loss of funds.
          </Alert>

          <PreviouslyUsedAddresses
            addresses={previousAddresses}
            onSelectAddress={handleAddressSelect}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 4 }}>
        <Button
          fullWidth
          color="primary"
          onClick={handleConfirm}
          variant="contained"
          disabled={disableSubmitButton}
        >
          Withdraw
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WithdrawalDialog;
