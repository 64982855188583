import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import isEqual from 'lodash.isequal';
import React, { useCallback, useState } from 'react';

import FileUploadField from '@component/DirectorDrawerForm/FileUploadField';
import LoadingButton from '@component/LoadingButton';
import BusinessOwnerKyc from '@interface/businessOwnerKyc.interface';
import { STATES_MAP } from '@variable';
import ConfirmationDialog from '@component/ConfirmationDialog';
import { useAppDispatch } from '@hook/hooks.hook';
import { requestBusinessOwnerVerification } from '@action/company.action';
import PhoneNumberField from '@component/PhoneNumberField';
import CountrySelectField from '@component/CountrySelectField';
import TutorialLink from '@component/TutorialLink';

const BusinessForm = ({
  isVerified,
  initialValues,
}: {
  isVerified: boolean;
  initialValues: BusinessOwnerKyc;
}) => {
  const { touched, setFieldValue, isValid, errors, isSubmitting, values } =
    useFormikContext<BusinessOwnerKyc>();

  const [openConfirmRequestVerification, setOpenConfirmRequestVerification] =
    useState(false);

  const hasEqualValues = isEqual(initialValues, {
    firstName: values.firstName,
    lastName: values.lastName,
    dateOfBirth: values.dateOfBirth,
    nationality: values.nationality,
    idUpload: values.idUpload,
    email: values.email,
    phoneNumber: values.phoneNumber,
    countryOfResidence: values.countryOfResidence,
    street: values.street,
    city: values.city,
    state: values.state,
    utilityBillUpload: values.utilityBillUpload,
  });

  const disableSubmitButton = !isValid || hasEqualValues;

  const showRequestVerificationButton =
    hasEqualValues &&
    initialValues.firstName &&
    initialValues.lastName &&
    initialValues.dateOfBirth &&
    initialValues.nationality &&
    initialValues.idUpload &&
    initialValues.email &&
    initialValues.phoneNumber &&
    initialValues.countryOfResidence &&
    initialValues.street &&
    initialValues.city &&
    initialValues.state &&
    initialValues.utilityBillUpload;

  const handleChangeCountryOfResidence = useCallback(
    (e: any) => {
      setFieldValue('state', '');
      setFieldValue('countryOfResidence', e.target.value);
    },
    [setFieldValue],
  );

  const dispatch = useAppDispatch();

  const handleConfirmRequestVerification = () => {
    dispatch(requestBusinessOwnerVerification());
  };

  return (
    <>
      <Stack justifyContent="space-between" direction="row">
        <Stack direction="row" gap={1} alignItems="center" mb={4}>
          <Typography variant="body1" fontSize="20px" fontWeight="bold">
            Business Owner KYC
          </Typography>
          <Chip
            size="small"
            color={isVerified ? 'secondary' : 'default'}
            label={isVerified ? 'Verified' : 'Not Verified'}
          />
        </Stack>

        <TutorialLink
          title={`How to complete "Business Owner KYC"`}
          link="https://www.youtube.com/watch?v=QLilyq4EePE"
        />
      </Stack>

      {showRequestVerificationButton && (
        <Alert severity="info" sx={{ mb: 4 }}>
          <AlertTitle sx={{ fontWeight: 'bold' }}>
            Request Verification
          </AlertTitle>
          Please review your details and click the &quot;Request
          Verification&quot; button to request verification
        </Alert>
      )}

      <Stack gap={3}>
        <Stack gap={3}>
          <Stack gap={3} direction="row">
            <Field
              sx={{ maxWidth: '370px' }}
              as={TextField}
              name="firstName"
              label="First Name"
              fullWidth
              variant="outlined"
              disabled
            />

            <Field
              sx={{ maxWidth: '370px' }}
              as={TextField}
              name="lastName"
              label="Last Name"
              fullWidth
              variant="outlined"
              disabled
            />
          </Stack>

          <Stack gap={3} direction="row">
            <Field
              sx={{ maxWidth: '370px' }}
              as={TextField}
              name="email"
              label="Email"
              fullWidth
              variant="outlined"
              disabled
            />

            <Field
              component={({ field, form }: any) => (
                <DatePicker
                  label="Date of Birth"
                  value={field.value ? dayjs(field.value) : undefined}
                  onChange={(value) => form.setFieldValue(field.name, value)}
                  sx={{ maxWidth: '370px', width: '100%' }}
                />
              )}
              name="dateOfBirth"
              fullWidth
              label="Date of Birth"
              type="date"
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              helperText={touched.dateOfBirth && errors.dateOfBirth}
            />
            {touched.dateOfBirth && Boolean(errors.dateOfBirth) && (
              <Typography variant="caption" color="error">
                Must be at least 18 years old
              </Typography>
            )}
          </Stack>

          <Stack gap={3} direction="row">
            <CountrySelectField
              name="nationality"
              label="Nationality"
              sx={{ maxWidth: '370px', width: '100%' }}
              error={touched.nationality && !!errors.nationality}
              helperText={touched.nationality && errors.nationality}
            />
          </Stack>
        </Stack>

        {/* LOCATION */}
        <Stack gap={3}>
          <Typography variant="body1" fontSize="18px" fontWeight={600}>
            Location
          </Typography>
          <Stack gap={3} direction="row">
            <CountrySelectField
              name="countryOfResidence"
              label="Country of Residence"
              sx={{ maxWidth: '370px', width: '100%' }}
              error={touched.countryOfResidence && !!errors.countryOfResidence}
              helperText={
                touched.countryOfResidence && errors.countryOfResidence
              }
              onChange={handleChangeCountryOfResidence}
            />

            <Field
              sx={{ maxWidth: '370px' }}
              as={TextField}
              name="state"
              label="Region"
              select
              fullWidth
              variant="outlined"
              error={touched.state && !!errors.state}
              helperText={touched.state && errors.state}
            >
              {(values.countryOfResidence
                ? STATES_MAP[values.countryOfResidence]
                : []
              ).map(({ value, title }: any) => (
                <MenuItem key={value} value={value}>
                  {title}
                </MenuItem>
              ))}
            </Field>
          </Stack>

          <Stack gap={3} direction="row">
            <Field
              sx={{ maxWidth: '370px' }}
              as={TextField}
              name="city"
              label="City"
              fullWidth
              variant="outlined"
              error={touched.city && !!errors.city}
              helperText={touched.city && errors.city}
            />

            <Field
              sx={{ maxWidth: '370px' }}
              as={TextField}
              name="street"
              label="Street"
              fullWidth
              variant="outlined"
              error={touched.street && !!errors.street}
              helperText={touched.street && errors.street}
            />
          </Stack>

          <Stack gap={3} direction="row">
            {values.countryOfResidence && (
              <PhoneNumberField
                as={TextField}
                name="phoneNumber"
                country={values.countryOfResidence}
                label="Phone Number"
                fullWidth
                variant="outlined"
                sx={{ maxWidth: '370px' }}
              />
            )}
          </Stack>
        </Stack>

        {/* Documents */}
        <Stack gap={3}>
          <Typography variant="body1" fontSize="18px" fontWeight={600}>
            Documents
          </Typography>

          <Stack gap={3} direction="row">
            <FileUploadField
              name="idUpload"
              uwConfig={{
                folder: 'identification',
                tags: ['apps', 'identification', 'verification'],
              }}
              label="Upload Identification (Only government issued IDs)"
            />

            <FileUploadField
              uwConfig={{
                folder: 'utility_bills',
                tags: ['apps', 'utility_bills', 'verification'],
              }}
              name="utilityBillUpload"
              label="Upload Proof of address (Utility bill)"
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack alignItems="flex-end" mt={2}>
        {showRequestVerificationButton ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              setOpenConfirmRequestVerification(true);
            }}
          >
            Request Verification
          </Button>
        ) : (
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            isLoading={isSubmitting}
            disabled={disableSubmitButton}
            size="large"
          >
            Save
          </LoadingButton>
        )}
      </Stack>

      <Divider
        variant="fullWidth"
        sx={{ width: 'calc(100% + 32px)', ml: '-16px', my: 5 }}
      />

      {openConfirmRequestVerification && (
        <ConfirmationDialog
          title="Request Verification"
          subtitle="Ensure you have checked all the details before you submit this form"
          open={openConfirmRequestVerification}
          onClose={() => {
            setOpenConfirmRequestVerification(false);
          }}
          onConfirm={handleConfirmRequestVerification}
          confirmButtonText="Submit"
          cancelButtonText="Cancel"
        />
      )}
    </>
  );
};

export default BusinessForm;
