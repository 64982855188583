import { Box, Stack } from '@mui/material';
import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { selectBusinessOwnerKyc } from '@selector/auth.selector';
import { getCompanyDetails, setPageTitle } from '@action/company.action';
import WalletBalances from '@page/Overview/WalletBalances';

import HeroSection from './HeroSection';
import KycRequirements from './KycRequirements';
import RecentTransactions from './RecentTransactions';

const Overview = () => {
  const dispatch = useAppDispatch();
  const businessOwnerKyc = useAppSelector(selectBusinessOwnerKyc);

  useEffect(() => {
    dispatch(getCompanyDetails());
    dispatch(setPageTitle('Overview'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let title = 'Welcome,';
  if (businessOwnerKyc.firstName)
    title = title.replace(',', ` ${businessOwnerKyc.firstName}`);

  return (
    <Box pb={4}>
      <HeroSection title={title} />

      <Stack px={3} pt={8} width="100%" direction="row" gap={3}>
        <Stack maxWidth="630px" width="100%" gap={3}>
          <KycRequirements />

          <RecentTransactions />
        </Stack>

        <WalletBalances />
      </Stack>
    </Box>
  );
};

export default Overview;
