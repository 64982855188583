import {
  AppBar,
  Box,
  LinearProgress,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';

import { useAppSelector } from '@hook/hooks.hook';
import { selectGlobalLoaderIsLoading } from '@selector/auth.selector';
import { selectPageTitle } from '@selector/company.selector';

import HeaderMenu from './HeaderMenu';

const Header = ({ width }: { width: number }) => {
  const globalLoaderIsLoading = useAppSelector(selectGlobalLoaderIsLoading);
  const pageTitle = useAppSelector(selectPageTitle);

  return (
    <AppBar
      position="fixed"
      variant="outlined"
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: 'common.white',
        py: '12px',
        width,
        left: '228px',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography
          variant="body1"
          color="primary.900"
          textTransform="capitalize"
          fontSize={20}
          fontWeight={600}
        >
          {pageTitle}
        </Typography>

        <HeaderMenu />
      </Toolbar>

      {globalLoaderIsLoading && (
        <Box color="secondary.700" width="100%">
          <LinearProgress color="inherit" sx={{ bottom: '-12px' }} />
        </Box>
      )}
    </AppBar>
  );
};

export default Header;
