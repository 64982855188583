import React, { useCallback, useState } from 'react';
import { Alert, Stack, Typography } from '@mui/material';
import { Person } from '@mui/icons-material';
import GroupsIcon from '@mui/icons-material/Groups';

import { useAppDispatch } from '@hook/hooks.hook';
import { updateCompany } from '@action/company.action';
import Company from '@interface/company.interface';
import warningGif from '@asset/gifs/warning.gif';
import AccountTypeRadio from '@component/PendingTerms/AccountTypeRadio';
import LoadingButton from '@component/LoadingButton';

const AccountType = ({ onGoToNextPage }: { onGoToNextPage: () => void }) => {
  const dispatch = useAppDispatch();
  const [isRegistered, setIsRegistered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const updateCompanyRegistrationStatus = useCallback(async () => {
    setIsLoading(true);
    const res: any = await dispatch(updateCompany({ isRegistered } as Company));
    if (!res.error) onGoToNextPage();
    setIsLoading(false);
  }, [dispatch, onGoToNextPage, isRegistered]);

  return (
    <Stack pt={2} alignItems="center" minHeight="calc(100vh - 78px)">
      <Typography
        color="grey.900"
        variant="h3"
        fontWeight={600}
        textAlign="center"
      >
        Kindly select an account type
      </Typography>

      <Stack gap={2} mb={4}>
        <Alert
          sx={{ mt: 3, mb: 2, textAlign: 'center', maxWidth: '620px' }}
          severity="warning"
          icon={<img src={warningGif} height={32} width={32} alt="sd" />}
        >
          Please read carefully before making your selection. This decision is
          permanent and cannot be changed later. If you need assistance or have
          made an error, please contact support at <b>support@zinari.io</b>.
        </Alert>

        <AccountTypeRadio
          title="Individual"
          description="Choose this if you do not own a registered company. You will not be able to add or manage team members."
          onClick={() => setIsRegistered(false)}
          checked={!isRegistered}
          icon={<Person />}
        />

        <AccountTypeRadio
          title="Company"
          description="Choose this if you own a registered company or work as part of a team. You will have access to team management features."
          onClick={() => setIsRegistered(true)}
          checked={isRegistered}
          icon={<GroupsIcon />}
        />
      </Stack>

      <LoadingButton
        color="primary"
        isLoading={isLoading}
        size="large"
        variant="contained"
        onClick={updateCompanyRegistrationStatus}
      >
        Get Started
      </LoadingButton>
    </Stack>
  );
};

export default AccountType;
