import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { auto } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { AdvancedImage } from '@cloudinary/react';
import { useNavigate } from 'react-router-dom';

import cld from '@localCloudinary';
import TabPanel from '@component/AppDetail/TabPanel';
import CloudinaryUploadWidget from '@component/CloudinaryUploadWidgets';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { deleteApp, updateApp } from '@action/app.action';
import App from '@interface/app.interface';
import { selectIsUpdatingApp } from '@selector/app.selector';
import LoadingButton from '@component/LoadingButton';
import SectionTitle from '@component/AppDetail/SectionDetail';
import CustomAlert from '@component/AppDetail/CustomAlert';
import ConfirmationDialog from '@component/ConfirmationDialog';

interface BasicInfoProps {
  id: string;
  name: string;
  logoUrl: string;
  value: number;
  index: number;
  customerPaysTax: boolean;
  customerPaysFees: boolean;
  taxRate: number;
  feeRate: number;
}

const validationSchema = Yup.object({
  name: Yup.string().required('App name is required'),
  logo: Yup.string(),
});

const BasicInfo: FC<BasicInfoProps> = ({
  id,
  name,
  value,
  logoUrl,
  index,
  taxRate,
  feeRate,
  customerPaysTax: initialCustomerPaysTax,
  customerPaysFees: initialCustomerPaysFees,
}) => {
  const [publicId, setPublicId] = useState(logoUrl);
  const dispatch = useAppDispatch();
  const isUpdatingApp = useAppSelector(selectIsUpdatingApp);

  const onSubmit = useCallback(
    async (values: any) => {
      await dispatch(
        updateApp({ id, name: values.name, logoUrl: values.logo } as App),
      );
    },
    [dispatch, id],
  );

  const [customerPaysTax, setCustomerPaysTax] = useState<boolean>(
    initialCustomerPaysTax,
  );
  const [customerPaysFees, setCustomerPaysFees] = useState<boolean>(
    initialCustomerPaysFees,
  );

  const isPayingTaxes = (taxRate || 0) > 0;

  const formik = useFormik({
    initialValues: { name, logo: logoUrl },
    validationSchema,
    onSubmit,
  });

  const handleSetPublicId = (publicId: string) => {
    setPublicId(publicId);
    formik.setFieldValue('logo', publicId);
  };

  const img = cld
    .image(publicId)
    .format('auto')
    .quality('auto')
    .resize(auto().gravity(autoGravity()).width(150).height(150));

  const onUpdateTaxOptions = useCallback(async () => {
    await dispatch(
      updateApp({
        id,
        customerPaysFees,
      } as App),
    );
  }, [customerPaysFees, dispatch, id]);

  useEffect(() => {
    if (customerPaysFees !== initialCustomerPaysFees) onUpdateTaxOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPaysFees]);

  const [openDeletionDialog, setOpenDeletionDialog] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleConfirmDeleteApp = async () => {
    const res = (await dispatch(deleteApp(id))) as any;
    if (!res.error) navigate('/dashboard/apps');
  };

  const confirmationDialogProps = {
    open: openDeletionDialog,
    color: 'error',
    title: 'Delete Application',
    subtitle: (
      <>
        Are you sure you want to delete
        <br />
        this application You can not undo
        <br /> this action once it&apos;s done
      </>
    ),
    onClose: () => {
      setOpenDeletionDialog(false);
    },
    onConfirm: handleConfirmDeleteApp,
    confirmButtonText: 'Delete',
    cancelButtonText: 'Cancel',
  };

  return (
    <TabPanel value={value} index={index}>
      <form onSubmit={formik.handleSubmit}>
        <Stack gap={4} mb={3}>
          <Stack gap={2} alignItems="flex-start">
            <SectionTitle
              title="Application Name"
              tooltip="This name will be displayed to your customers at payment time"
            />

            <TextField
              required
              name="name"
              variant="filled"
              label="App Name"
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              sx={{ width: '100%', maxWidth: 400 }}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : ''
              }
            />
          </Stack>

          <Stack gap={2} alignItems="flex-start">
            <SectionTitle
              title="Logo"
              tooltip="This logo will be displayed to your customers at payment time"
            />

            <CloudinaryUploadWidget setPublicId={handleSetPublicId}>
              {Boolean(publicId) && (
                <Box height={150} width={150}>
                  <AdvancedImage cldImg={img} />
                </Box>
              )}
            </CloudinaryUploadWidget>

            <CustomAlert>
              <Stack>
                <Typography variant="caption" fontWeight={400}>
                  - Maximum size is 1MB.
                </Typography>
                <Typography variant="caption" fontWeight={400}>
                  - Only PNG, JPEG, JPG and WebP are allowed.
                </Typography>
              </Stack>
            </CustomAlert>

            {formik.touched.logo && formik.errors.logo && (
              <Typography color="error">{formik.errors.logo}</Typography>
            )}
          </Stack>

          <Stack alignItems="flex-end">
            <LoadingButton
              isLoading={isUpdatingApp}
              variant="contained"
              size="large"
              disabled={
                (!formik.values.name || formik.values.name === name) &&
                (!formik.values.logo || formik.values.logo === logoUrl)
              }
              type="submit"
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>

        <Divider
          variant="fullWidth"
          sx={{ width: 'calc(100% + 64px)', ml: '-32px' }}
        />

        {/* Tax Options Section */}
        <Box pt={5} pb={10}>
          <SectionTitle
            title="Processing Fees"
            tooltip="This is how we will calculate the fees for each transaction"
          />

          <Stack spacing={2} mt={2}>
            {isPayingTaxes && (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={isUpdatingApp}
                      checked={customerPaysTax}
                      onChange={(e) => setCustomerPaysTax(e.target.checked)}
                    />
                  }
                  label={<Typography ml={3}>Customer Pays Tax?</Typography>}
                />
                <CustomAlert>
                  <Typography variant="caption" fontWeight={400}>
                    {customerPaysTax
                      ? `We will charge the customer an additional ${(
                          taxRate * 100
                        ).toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                        })}% for VAT on top of the total amount you charged`
                      : `We will deduct the ${(taxRate * 100).toLocaleString(
                          'en-US',
                          {
                            maximumFractionDigits: 2,
                          },
                        )}% VAT from the total amount you charged the customer`}
                  </Typography>
                </CustomAlert>
              </>
            )}
            <FormControlLabel
              control={
                <Switch
                  disabled={isUpdatingApp}
                  checked={customerPaysFees}
                  onChange={(e) => setCustomerPaysFees(e.target.checked)}
                  color="secondary"
                />
              }
              label={<Typography ml={3}>Customer Pays Fees?</Typography>}
            />
            <CustomAlert>
              <Typography variant="caption" fontWeight={400}>
                {customerPaysFees
                  ? `This toggle is enabled, meaning the customer will be charged an additional ${
                      feeRate * 100
                    }% fee`
                  : `This toggle is disabled, meaning you will be charged the additional ${
                      feeRate * 100
                    }% fee`}
              </Typography>
            </CustomAlert>
          </Stack>
        </Box>

        <Divider
          variant="fullWidth"
          sx={{ width: 'calc(100% + 64px)', ml: '-32px' }}
        />

        <Stack spacing={2} pt={5} pb={10}>
          <SectionTitle title="Delete Application" />

          <Typography color="grey.700" fontWeight={400} maxWidth="400px">
            Deleting this application will remove all data associated with it.
            This action cannot be undone.
          </Typography>

          <Stack alignItems="flex-end">
            <Button
              variant="contained"
              color="error"
              size="large"
              onClick={() => {
                setOpenDeletionDialog(true);
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>

        <Divider
          variant="fullWidth"
          sx={{ width: 'calc(100% + 64px)', ml: '-32px' }}
        />
      </form>

      {openDeletionDialog && (
        <ConfirmationDialog {...confirmationDialogProps} />
      )}
    </TabPanel>
  );
};

export default BasicInfo;
