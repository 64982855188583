import { allCountries, CountryName, CountrySlug } from 'country-region-data';

import CompanyType from '@enum/companyType.enum';
import UserRole from '@enum/userRole.enum';
import { CryptoCurrency } from '@enum/cryptocurrency.enum';
import InitialState from '@interface/initialState.interface';
import { AppTransactionStatus } from '@enum/appTransactionStatus.enum';
import USDT from '@asset/images/usdt.webp';
import USDC from '@asset/images/usdc.webp';

const {
  NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_CLOUDINARY_CLOUD_NAME,
  REACT_APP_CLOUDINARY_API_KEY,
  REACT_APP_AUTH_0_AUDIENCE,
  REACT_APP_AUTH_0_DOMAIN,
  REACT_APP_CLOUDINARY_API_SECRET,
  REACT_APP_CLOUDINARY_UPLOAD_PRESET,
  REACT_APP_TAWKTO_PROPERTY_ID,
  REACT_APP_TAWKTO_WIDGET_ID,
  REACT_APP_ORIGIN_URL,
} = process.env;

export const SLICE_NAME = 'zinari-pay-dashboard';
export const TOKEN_KEY = 'zinariPayDashboardAccessToken';
export const IS_PROD = NODE_ENV === 'production';
export const IS_DEV = !IS_PROD;
export const STANDARD_DATE_FORMAT = 'YYYY-MM-DD';
export const API_URL = REACT_APP_API_URL;
export const ORIGIN_URL = REACT_APP_ORIGIN_URL;
export const CLOUDINARY_CLOUD_NAME = REACT_APP_CLOUDINARY_CLOUD_NAME;
export const CLOUDINARY_API_KEY = REACT_APP_CLOUDINARY_API_KEY;
export const AUTH_0_AUDIENCE = REACT_APP_AUTH_0_AUDIENCE;
export const AUTH_0_DOMAIN = REACT_APP_AUTH_0_DOMAIN as string;
export const TAWKTO_PROPERTY_ID = REACT_APP_TAWKTO_PROPERTY_ID as string;
export const TAWKTO_WIDGET_ID = REACT_APP_TAWKTO_WIDGET_ID as string;
export const AUTH_0_CLIENT_ID = process.env
  .REACT_APP_AUTH_0_CLIENT_ID as string;
export const CLOUDINARY_API_SECRET = REACT_APP_CLOUDINARY_API_SECRET;
export const CLOUDINARY_UPLOAD_PRESET = REACT_APP_CLOUDINARY_UPLOAD_PRESET;
export const HTTPS_URL_REGEX =
  /^(https:\/\/[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+|http:\/\/localhost:\d+)$/;
export const HTTPS_WEBHOOK_URL =
  /^(https:\/\/[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+(?:\/[^\s\\/]+)*|http:\/\/localhost:\d+(?:\/[^\s\\/]+)*)$/;

export const PHONE_NUMBER_REGEX = /^(?:\+234|0)?[789]\d{9}$/;
export const BVN_VALIDATION = /^\d{11}$/;

export const WITHDRAWAL_FEES: { [key: string]: number } = {
  [CryptoCurrency.USDT]: 3,
  [CryptoCurrency.USDC]: 3,
};

export const MIN_WITHDRAWAL_AMOUNT: { [key: string]: number } = {
  [CryptoCurrency.USDT]: 100,
  [CryptoCurrency.USDC]: 100,
};

export const CRYPTO_NETWORKS: { [key: string]: string } = {
  [CryptoCurrency.USDT]: 'TRC20',
  [CryptoCurrency.USDC]: 'TRC20',
};

export const INITIAL_STATE: InitialState = {
  apps: [],
  appTransactions: {},
  teamMembers: [],
  isLoadingAppTransactions: {},
  isLoadingApps: false,
  isAuthenticated: false,
  isWithdrawing: false,
  isLoadingCompanyTeamMembers: false,
  isDeletingCompanyDirector: false,
  isRequestingCompanyDirectorVerification: false,
  isAddingOrUpdatingBusinessOwnerKyc: false,
  isFetchingPayLinks: false,
  isRehiringTeamMember: false,
  isChangingPassword: false,
  isUpdatingUser: false,
  isCreatingApp: false,
  isUpdatingApp: false,
  isCreatingCompanyDirector: false,
  isAddingTeamMember: false,
  isUpdatingCompany: false,
  isTerminatingTeamMember: false,
  isFetchingCompanyAppTransactions: false,
  isAuthenticating: false,
  isUpdatingTeamMember: false,
  isUpdatingCompanyDirector: false,
  isLoadingCompany: false,
  pageTitle: '',
};

export const ALLOWED_CRYPTOCURRENCIES = [
  CryptoCurrency.USDT,
  CryptoCurrency.USDC,
];

export const COMPANY_TYPES = [
  { value: CompanyType.LLC, label: 'LLC' },
  { value: CompanyType.SOLE_PROPRIETOR, label: 'Sole Proprietor' },
  { value: CompanyType.PARTNERSHIP, label: 'Partnership' },
  { value: CompanyType.CORPORATION, label: 'Corporation' },
  { value: CompanyType.S_CORP, label: 'S-Corp' },
  { value: CompanyType.NONPROFIT, label: 'Nonprofit' },
  { value: CompanyType.COOPERATIVE, label: 'Cooperative' },
  { value: CompanyType.TRUST, label: 'Trust' },
];

export const UW_CONFIG = {
  cloudName: CLOUDINARY_CLOUD_NAME,
  uploadPreset: CLOUDINARY_UPLOAD_PRESET,
  cropping: true,
  croppingAspectRatio: 1,
  multiple: false,
  sources: ['local', 'url'],
  folder: 'app_logos',
  tags: ['apps', 'logos'],
  context: { alt: 'user_uploaded' },
  clientAllowedFormats: ['jpeg', 'png', 'webp', 'jpg'],
  maxImageFileSize: 1000000,
  maxImageWidth: 150,
  maxImageHeight: 150,
  signed: true,
};

export const CRYPTOCURRENCY_ICONS: {
  [key: string]: any;
} = {
  USDT,
  USDC,
};

export const ROLE_MAP: any = {
  [UserRole.SUPER_ADMIN]: 'Super Admin',
  [UserRole.ADMIN]: 'Admin',
  [UserRole.DEVELOPER]: 'Software Developer',
  [UserRole.BUSINESS_OWNER]: 'Business Owner',
  [UserRole.FINANCE]: 'Finance',
  [UserRole.CUSTOMER_SUPPORT]: 'Customer Support',
  [UserRole.BUSINESS_ACCOUNT_MANAGER]: 'Business Account Manager',
  [UserRole.BUSINESS_ADMIN]: 'Admin',
  [UserRole.USER]: 'User',
};

export const ALLOWED_NATIONALITIES = allCountries.reduce(
  (memo, [name, code, regions]) => {
    if (!code || !name || !regions) return memo;

    memo.push({
      code,
      name,
      regions: regions.reduce((regionMemo, [title, value]) => {
        regionMemo.push({ title, value });
        return regionMemo;
      }, [] as any),
    });
    return memo;
  },
  [] as {
    code: CountrySlug;
    name: CountryName;
    regions: {
      title: string;
      value: string;
    }[];
  }[],
);

export const COUNTRY_NAME = ALLOWED_NATIONALITIES.reduce(
  (memo, { code, name }) => {
    if (!code || !name) return memo;
    memo[code] = name;
    return memo;
  },
  {} as any,
);

export const ALLOWED_NATIONALITIES_MAP = ALLOWED_NATIONALITIES.map(
  ({ code: value, name: title }) => ({ value, title }),
);

export const STATES_MAP = ALLOWED_NATIONALITIES.reduce(
  (memo, { code, regions }) => {
    if (!code) return memo;
    memo[code] = regions;
    return memo;
  },
  {} as {
    [key: string]: {
      title: string;
      value: string;
    }[];
  },
);

export const getRegionName = (countrySlug: string, state: string) => {
  return STATES_MAP[countrySlug].find((curr) => curr.value === state)?.title;
};

export const USER_ROLES = [
  { title: ROLE_MAP[UserRole.BUSINESS_ADMIN], value: UserRole.BUSINESS_ADMIN },
  { title: ROLE_MAP[UserRole.DEVELOPER], value: UserRole.DEVELOPER },
  {
    title: ROLE_MAP[UserRole.CUSTOMER_SUPPORT],
    value: UserRole.CUSTOMER_SUPPORT,
  },
];

export const APP_TRANSACTION_STATUS_COLORS: any = {
  [AppTransactionStatus.PENDING]: 'default',
  [AppTransactionStatus.CONFIRMING]: 'info',
  [AppTransactionStatus.INCOMPLETE]: 'warning',
  [AppTransactionStatus.EXCESS]: 'secondary',
  [AppTransactionStatus.CANCELLED]: 'error',
  [AppTransactionStatus.CONFIRMED]: 'success',
};

export const FIAT_CURRENCIES = [
  // African
  { name: 'Nigerian Naira', code: 'NGN', symbol: '₦' },
  { name: 'South African Rand', code: 'ZAR', symbol: 'R' },
  { name: 'Kenyan Shilling', code: 'KES', symbol: '₵' },
  // { name: 'Ghanaian Cedi', code: 'GHS', symbol: 'GH₵' },
  // Asian
  { name: 'Indian Rupee', code: 'INR', symbol: '₹' },
  // America
  { name: 'United States Dollar', code: 'USD', symbol: '$' },
  // Europe
  { name: 'Euro', code: 'EUR', symbol: '€' },
  { name: 'British Pound', code: 'GBP', symbol: '£' },
  { name: 'Canadian Dollar', code: 'CAD', symbol: '$' },
  // Middle east
  { name: 'United Arab Emirates Dirham', code: 'AED', symbol: 'د.إ' },
];

export const FIAT_SYMBOLS = FIAT_CURRENCIES.reduce((memo, { code, symbol }) => {
  memo[code] = symbol;
  return memo;
}, {} as { [key: string]: string });

export const JOB_LIFESPANS_LONG: { [key: string]: string } = {
  '10m': '10 minutes',
  '1h': '1 hour',
  '3h': '3 hours',
  '8h': '8 hours',
  '16h': '16 hours',
  '1d': '1 day',
};

export const LIFESPAN_OPTION: {
  value: string;
  title: string;
}[] = Object.entries(JOB_LIFESPANS_LONG).map(([key, value]) => ({
  value: key,
  title: value,
}));
