import { Box, Button, Stack, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { selectApps, selectIsLoadingApps } from '@selector/app.selector';
import { fetchApps } from '@action/app.action';
import AppLoader from '@component/AppLoader';
import CreateAppDialog from '@component/CreateAppDialog';
import App from '@interface/app.interface';
import AppCard from '@component/AppCard';
import { setPageTitle } from '@action/company.action';

import NoApps from './NoApps';

const Apps = ({ apps }: { apps: App[] }) => {
  return (
    <Stack px={2}>
      {apps.map((app) => (
        <AppCard {...app} key={app.id} />
      ))}
    </Stack>
  );
};

const CompanyApps = () => {
  const dispatch = useAppDispatch();
  const apps = useAppSelector(selectApps);
  const isLoadingApps = useAppSelector(selectIsLoadingApps);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);

  useEffect(() => {
    dispatch(fetchApps());
    dispatch(setPageTitle('Apps'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleOpenCreateDialog = () => {
    setOpenCreateDialog((value) => !value);
  };

  if (isLoadingApps && !apps.length) return <AppLoader />;

  return (
    <Box>
      {!apps.length ? (
        <NoApps />
      ) : (
        <>
          <Toolbar sx={{ justifyContent: 'space-between', my: 3 }}>
            <Typography variant="h5" fontWeight={500}>
              List of Applications
            </Typography>

            <Button
              color="primary"
              onClick={toggleOpenCreateDialog}
              variant="contained"
              sx={{ fontWeight: 500 }}
            >
              Add new app
            </Button>

            <CreateAppDialog
              open={openCreateDialog}
              onClose={toggleOpenCreateDialog}
            />
          </Toolbar>

          <Apps apps={apps} />
        </>
      )}
    </Box>
  );
};

export default CompanyApps;
