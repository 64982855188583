import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import './AppSelector.scss';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { useFormikContext } from 'formik';

import { useAppSelector } from '@hook/hooks.hook';
import { selectIsLoadingApps } from '@selector/app.selector';
import { selectIsFetchingCompanyAppTransactions } from '@selector/company.selector';
import { AdvancedFilterProps } from '@page/AppTransactions/index.interface';

const AppSelector = ({
  options,
}: {
  options: { title: string; value: string }[];
}) => {
  const {
    values: { apps },
    setFieldValue,
  } = useFormikContext<AdvancedFilterProps>();

  const isLoadingApps = useAppSelector(selectIsLoadingApps);
  const isFetchingCompanyAppTransactions = useAppSelector(
    selectIsFetchingCompanyAppTransactions,
  );

  const handleChange = ({ target: { value } }: any) => {
    if (value.length) setFieldValue('apps', value);
  };

  const renderValue = (selected: string[]) =>
    `${selected.length} out of ${options.length} ${
      options.length === 1 ? 'app' : 'apps'
    } selected`;

  return (
    <div>
      <FormControl
        className="AppSelector"
        disabled={isLoadingApps || isFetchingCompanyAppTransactions}
      >
        <InputLabel id="demo-multiple-checkbox-label">Apps</InputLabel>

        <Select
          multiple
          value={apps}
          onChange={handleChange}
          renderValue={renderValue}
          id="demo-multiple-checkbox"
          input={<OutlinedInput label="Apps" />}
          labelId="demo-multiple-checkbox-label"
        >
          {options.map(({ title, value }) => (
            <MenuItem key={title} value={value}>
              <Checkbox checked={apps.includes(value)} color="info" />
              <ListItemText primary={title} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default AppSelector;
