import { Alert, Box, Stack } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { ORIGIN_URL } from '@variable';
import { agreeTerms } from '@action/user.action';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import LoadingButton from '@component/LoadingButton';
import {
  selectIsAuthenticating,
  selectIsUpdatingUser,
} from '@selector/auth.selector';

import Content from './Content';
import AccountType from './AccountType';

const Terms = () => {
  const { logout } = useAuth0();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLElement>();
  const isAuthenticating = useAppSelector(selectIsAuthenticating);
  const isUpdatingUser = useAppSelector(selectIsUpdatingUser);

  const [disableButton, setDisableButton] = useState(true);
  const handleScroll = (element: any) => {
    const eightyPercent = element.target.scrollHeight * 0.95;

    const currentPosition =
      element.target.scrollTop + element.target.clientHeight;
    const isScrolledToEnd = eightyPercent < currentPosition;

    if (isScrolledToEnd) setDisableButton(false);
  };

  const logoutUser = useCallback(async () => {
    await logout({
      logoutParams: { returnTo: ORIGIN_URL + '?action=logout' },
    });
  }, [logout]);

  const onAgreeTerms = async () => {
    await dispatch(agreeTerms());
  };

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Box overflow="scroll" pt={18} pb={15} ref={ref}>
        <Content />
      </Box>

      <Stack
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          py: 2,
          px: { md: '100px', xs: 3 },
        }}
        bgcolor="common.white"
        width="100%"
        gap={2}
        justifyContent="flex-end"
        direction="row"
      >
        <LoadingButton
          isLoading={isAuthenticating || isUpdatingUser}
          color="primary"
          variant="contained"
          disabled={disableButton}
          onClick={onAgreeTerms}
        >
          I Agree
        </LoadingButton>
        <LoadingButton
          isLoading={isAuthenticating}
          disabled={disableButton || isUpdatingUser}
          color="primary"
          variant="outlined"
          onClick={logoutUser}
        >
          Decline
        </LoadingButton>
      </Stack>
    </>
  );
};

const PAGES = {
  accountType: 0,
  terms: 1,
};

const PendingTerms = () => {
  const [page, setPage] = useState(PAGES.accountType);

  const goToNextPage = () => {
    setPage((value) => value + 1);
  };

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          py: 2,
          px: { md: '100px', xs: 3 },
          zIndex: 3,
        }}
        bgcolor="common.white"
        width="100%"
      >
        <img
          width={190}
          height="auto"
          alt="zinaripay logo"
          src="/logoDefault.webp"
        />

        {page === PAGES.terms && (
          <Alert severity="info">
            You must read and scroll through the entire Terms and Conditions to
            enable the &quot;I Agree&quot; button. Please review the agreement
            carefully.
          </Alert>
        )}

        {page === PAGES.accountType && (
          <AccountType onGoToNextPage={goToNextPage} />
        )}
      </Box>

      {page === PAGES.terms && <Terms />}
    </Box>
  );
};

export default PendingTerms;
