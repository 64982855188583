import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@store';
import { SLICE_NAME } from '@variable';
import UserRole from '@enum/userRole.enum';
import { selectCompanyInfo } from '@selector/company.selector';

export const selectUser = (state: RootState) => state[SLICE_NAME].user;

export const selectUserRole = createSelector(selectUser, (user) => user?.role);

export const selectIsBusinessOwner = createSelector(selectUserRole, (role) =>
  [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.BUSINESS_OWNER].includes(
    role as UserRole,
  ),
);

export const selectCanViewCompanyDirectors = createSelector(
  selectUserRole,
  (role) =>
    [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BUSINESS_OWNER,
      UserRole.BUSINESS_ADMIN,
    ].includes(role as UserRole),
);

export const selectCanUpdateEmail = createSelector(selectUserRole, (role) =>
  [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.BUSINESS_OWNER,
    UserRole.BUSINESS_ADMIN,
  ].includes(role as UserRole),
);

export const selectCanViewSidebarTeams = createSelector(
  selectCompanyInfo,
  selectUserRole,
  (company, role) =>
    company?.isRegistered &&
    [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BUSINESS_OWNER,
      UserRole.BUSINESS_ADMIN,
    ].includes(role as UserRole),
);

export const selectCanViewOverviewPage = createSelector(
  selectUserRole,
  (role) =>
    [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BUSINESS_OWNER,
      UserRole.BUSINESS_ADMIN,
    ].includes(role as UserRole),
);

export const selectCanViewSidebarAppTransactions = createSelector(
  selectUserRole,
  (role) =>
    [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BUSINESS_OWNER,
      UserRole.CUSTOMER_SUPPORT,
      UserRole.FINANCE,
    ].includes(role as UserRole),
);

export const selectCanViewSidebarApps = createSelector(selectUserRole, (role) =>
  [
    UserRole.SUPER_ADMIN,
    UserRole.ADMIN,
    UserRole.DEVELOPER,
    UserRole.BUSINESS_OWNER,
    UserRole.BUSINESS_ADMIN,
  ].includes(role as UserRole),
);

export const selectCanEditCompanyDetails = createSelector(
  selectUserRole,
  (role) =>
    [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BUSINESS_OWNER,
      UserRole.BUSINESS_ADMIN,
    ].includes(role as UserRole),
);

export const selectCanWithdrawFromWallet = createSelector(
  selectUserRole,
  (role) =>
    [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.BUSINESS_OWNER,
      UserRole.BUSINESS_ADMIN,
    ].includes(role as UserRole),
);
