import Typography from '@mui/material/Typography';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Stack } from '@mui/material';

const TutorialLink = ({ link, title }: { link: string; title: string }) => {
  const openLink = () => {
    if (link) window.open(link, '_blank');
  };

  return (
    <Stack
      p={1}
      gap={1}
      direction="row"
      borderRadius={1}
      onClick={openLink}
      height="fit-content"
      bgcolor="primary.50"
    >
      <YouTubeIcon sx={{ color: '#D32F2F' }} />
      <Typography fontWeight={600}>{title}</Typography>
    </Stack>
  );
};

export default TutorialLink;
