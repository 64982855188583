import { Radio, Stack, Typography } from '@mui/material';
import React from 'react';

const AccountTypeRadio = ({
  icon,
  checked,
  onClick,
  title,
  description,
}: {
  icon: any;
  title: string;
  description: string;
  checked: boolean;
  onClick: () => void;
}) => {
  return (
    <Stack
      p={3}
      gap={3}
      sx={{ cursor: 'pointer' }}
      direction={{ md: 'row', xs: 'column' }}
      border="1px solid"
      onClick={onClick}
      borderRadius="12px"
      justifyContent={{ md: 'space-between', xs: 'center' }}
      alignItems="center"
      borderColor={checked ? 'primary.400' : 'grey.100'}
    >
      <Stack
        width="80px"
        height="80px"
        minWidth="80px"
        minHeight="80px"
        fontSize="32px"
        borderRadius="50%"
        alignItems="center"
        bgcolor="primary.50"
        justifyContent="center"
      >
        {icon}
      </Stack>

      <Stack maxWidth="400px" gap={1} textAlign={{ md: 'left', xs: 'center' }}>
        <Typography fontWeight={600} fontSize="18px">
          {title}
        </Typography>

        <Typography>{description}</Typography>
      </Stack>

      <Radio
        sx={{ display: { xs: 'none', md: 'block' } }}
        color="secondary"
        checked={checked}
        onClick={onClick}
      />
    </Stack>
  );
};

export default AccountTypeRadio;
