import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, Fragment, useCallback, useState } from 'react';
import isEqual from 'lodash.isequal';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';

import TabPanel from '@component/AppDetail/TabPanel';
import CopyToClipboard from '@component/CopyToClipboard';
import WebhookSection from '@component/AppDetail/WebhookSection';
import { CryptoCurrency } from '@enum/cryptocurrency.enum';
import AppCurrencies from '@component/AppDetail/AppCurrencies';
import ConfirmationDialog from '@component/ConfirmationDialog';
import {
  generateNewApiKey,
  generateNewPublicKey,
  updateApp,
} from '@action/app.action';
import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import { HTTPS_URL_REGEX } from '@variable';
import App from '@interface/app.interface';
import { selectIsUpdatingApp } from '@selector/app.selector';
import LoadingButton from '@component/LoadingButton';

import CustomAuthorizationSection from './CustomAuthorizationSection';

interface ApiInfoProps {
  id: string;
  value: number;
  transactionDetectedWebhookUrl?: string;
  transactionDoneWebhookUrl?: string;
  customAuthorizationKey?: string;
  incompletePaymentCompletionWebhookUrl?: string;
  apiKey?: string;
  publicKey: string;
  fiatCurrency: string;
  index: number;
  appCurrencies: CryptoCurrency[];
  whitelistedUrls: string[];
}

interface CopyableApiInfoProps {
  label: string;
  value?: string;
  id: string;
  showGenerateNewKey?: boolean;
}

const CopyableApiInfo: FC<CopyableApiInfoProps> = ({
  id,
  label,
  value,
  showGenerateNewKey,
}) => {
  const isApiKey = label === 'API Key';
  const dispatch = useAppDispatch();
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  const handleGenerateNewPublicKey = () => {
    if (isApiKey) dispatch(generateNewApiKey(id));
    else dispatch(generateNewPublicKey(id));
  };

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };

  const confirmationDialogProps = {
    open: openConfirmationDialog,
    title: `Generate ${isApiKey ? 'APi' : 'Public'} Key`,
    confirmButtonText: 'Generate',
    subtitle: (
      <>
        Generating a new <b>{isApiKey ? 'API' : 'PUBLIC'} KEY</b> will
        invalidate
        <br />
        your current one. All existing integrations that
        <br />
        use this <b>KEY</b> will stop working until you update
        <br />
        them with the new <b>KEY</b>.
      </>
    ),
    onClose: () => {
      setOpenConfirmationDialog(false);
    },
    onConfirm: handleGenerateNewPublicKey,
  };

  return (
    <>
      <Stack direction="row" gap={2} justifyContent="space-between">
        <Stack direction="row" gap={2} alignItems="center">
          <Typography color="grey.900" fontWeight={500}>
            {label}:
          </Typography>

          <Typography color="primary.500">
            {value ? `${value.substring(0, 8)}***` : `N/A`}
          </Typography>

          {value && <CopyToClipboard value={value} />}
        </Stack>

        {showGenerateNewKey && (
          <Button
            onClick={handleOpenConfirmationDialog}
            size="small"
            variant="text"
          >
            {value ? `Generate new ${label}` : `Create new ${label}`}
          </Button>
        )}
      </Stack>

      {openConfirmationDialog && (
        <ConfirmationDialog {...confirmationDialogProps} />
      )}
    </>
  );
};

const ApiInfo: FC<ApiInfoProps> = ({
  id,
  value,
  publicKey,
  apiKey,
  customAuthorizationKey: defaultCustomAuthorizationKey,
  transactionDoneWebhookUrl: defaultTransactionDoneWebhookUrl,
  transactionDetectedWebhookUrl: defaultTransactionDetectedWebhookUrl,
  incompletePaymentCompletionWebhookUrl:
    defaultIncompletePaymentCompletionWebhookUrl,
  index,
  appCurrencies,
  fiatCurrency,
  whitelistedUrls: initialWhitelistedUrls,
}) => {
  const [whitelistedUrls, setWhitelistedUrls] = useState<string[]>(
    initialWhitelistedUrls,
  );
  const [newUrl, setNewUrl] = useState<string>('');
  const isUpdatingApp = useAppSelector(selectIsUpdatingApp);

  const copyableApiInfo = [
    {
      label: 'App ID',
      value: id,
    },
    {
      label: 'Public Key',
      value: publicKey,
      showGenerateNewKey: true,
    },
    {
      label: 'API Key',
      value: apiKey,
      showGenerateNewKey: true,
    },
  ];

  const handleAddUrl = () => {
    if (
      newUrl &&
      HTTPS_URL_REGEX.test(newUrl) &&
      !whitelistedUrls.includes(newUrl)
    ) {
      setWhitelistedUrls([...whitelistedUrls, newUrl]);
      setNewUrl('');
    }
  };

  const handleRemoveUrl = (url: string) => {
    setWhitelistedUrls(whitelistedUrls.filter((item) => item !== url));
  };
  const dispatch = useAppDispatch();
  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      await dispatch(
        updateApp({
          id,
          whitelistedUrls,
        } as App),
      );
    },
    [dispatch, id, whitelistedUrls],
  );

  const disableUrlButton = isEqual(whitelistedUrls, initialWhitelistedUrls);

  return (
    <TabPanel value={value} index={index}>
      <Stack spacing={3}>
        <Stack gap="12px">
          <Typography variant="body1" fontSize="20px" fontWeight="bold">
            Integration Information
          </Typography>
          <Typography variant="body1" color="grey.700">
            Use this information to integrate Zinari Pay into your application.
          </Typography>
        </Stack>

        <Box
          sx={{}}
          p={2}
          border="1px solid"
          borderColor="primary.50"
          borderRadius={1}
          pt={4}
        >
          {copyableApiInfo.map((apiInfo) => (
            <Fragment key={apiInfo.label}>
              <CopyableApiInfo {...apiInfo} id={id} />
              <Divider variant="fullWidth" sx={{ mt: 2, mb: 5 }} />
            </Fragment>
          ))}
        </Box>
      </Stack>

      <Divider
        variant="fullWidth"
        sx={{ width: 'calc(100% + 64px)', ml: '-32px', my: 5 }}
      />

      <AppCurrencies
        id={id}
        fiatCurrency={fiatCurrency}
        appCurrencies={appCurrencies}
      />

      <Divider
        variant="fullWidth"
        sx={{ width: 'calc(100% + 64px)', ml: '-32px', my: 5 }}
      />

      {/* Whitelisted URLs Section */}
      <Stack spacing={3}>
        <Stack gap="12px">
          <Typography variant="body1" fontSize="20px" fontWeight="bold">
            Whitelisted URLs
          </Typography>
          <Typography variant="body1" color="grey.700">
            This URLs will be able to call your app
          </Typography>
        </Stack>

        <form onSubmit={onSubmit}>
          <Stack
            spacing={3}
            mt={2}
            direction="row"
            alignItems="flex-start"
            flexWrap="wrap"
            gap={3}
          >
            <TextField
              name="url"
              value={newUrl}
              label="New URL"
              sx={{ width: 400 }}
              helperText={
                newUrl && !HTTPS_URL_REGEX.test(newUrl)
                  ? 'Invalid url'
                  : 'Add the URLs that will be calling your app'
              }
              error={Boolean(newUrl && !HTTPS_URL_REGEX.test(newUrl))}
              onChange={(e) => setNewUrl(e.target.value)}
            />

            {whitelistedUrls.map((url, index) => (
              <Stack
                key={index}
                alignItems="center"
                direction="row"
                gap={2}
                justifyContent="center"
                height="56px"
              >
                <Typography color="grey.700">{url}</Typography>
                <IconButton color="error" onClick={() => handleRemoveUrl(url)}>
                  <DeleteForeverRoundedIcon />
                </IconButton>
              </Stack>
            ))}
          </Stack>

          <Stack direction="row" pt={4} gap={4} justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={handleAddUrl}
              size="large"
              disabled={
                !HTTPS_URL_REGEX.test(newUrl) ||
                whitelistedUrls.includes(newUrl)
              }
            >
              Add URL
            </Button>

            {!disableUrlButton && (
              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                isLoading={isUpdatingApp}
              >
                Update URL(s)
              </LoadingButton>
            )}
          </Stack>
        </form>
      </Stack>

      <Divider
        variant="fullWidth"
        sx={{ width: 'calc(100% + 64px)', ml: '-32px', my: 5 }}
      />

      <WebhookSection
        id={id}
        transactionDoneWebhookUrl={defaultTransactionDoneWebhookUrl || ''}
        transactionDetectedWebhookUrl={
          defaultTransactionDetectedWebhookUrl || ''
        }
        incompletePaymentCompletionWebhookUrl={
          defaultIncompletePaymentCompletionWebhookUrl || ''
        }
      />

      <Divider
        variant="fullWidth"
        sx={{ width: 'calc(100% + 64px)', ml: '-32px', my: 5 }}
      />

      <CustomAuthorizationSection
        id={id}
        customAuthorizationKey={defaultCustomAuthorizationKey || ''}
      />

      <Divider
        variant="fullWidth"
        sx={{ width: 'calc(100% + 64px)', ml: '-32px', my: 5 }}
      />
    </TabPanel>
  );
};

export default ApiInfo;
